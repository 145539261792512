import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
  useEffect,
} from "react";
import { useAuthContext } from "./authContext";

export type InsightsTab = "Macro" | "Equities" | "Themes";

interface InsightsContextType {
  tabs: InsightsTab[];
  activeTab: InsightsTab;
  setActiveTab: (tab: InsightsTab) => void;
  canViewMacro: boolean;
  canViewThemes: boolean;
}

const defaultInsightsState: InsightsContextType = {
  tabs: [],
  activeTab: "Equities",
  setActiveTab: () => {},
  canViewMacro: false,
  canViewThemes: false,
};

const InsightsContext =
  createContext<InsightsContextType>(defaultInsightsState);

export const useInsightsContext = () => useContext(InsightsContext);

interface InsightsProviderProps {
  children: ReactNode;
}

export const InsightsProvider: React.FC<InsightsProviderProps> = ({
  children,
}) => {
  const { whoami } = useAuthContext();
  const canViewMacro = (whoami as any)?.can_view_macro;
  const canViewThemes = (whoami as any)?.can_view_themes;

  const [activeTab, setActiveTab] = useState<InsightsTab>("Macro");
  const [tabs, setTabs] = useState<InsightsTab[]>(["Macro"]);

  // Adjust the active tab based on permissions
  useEffect(() => {
    const newTabs: InsightsTab[] = [
      ...(canViewMacro ? ["Macro"] : []),
      "Equities",
      ...(canViewThemes ? ["Themes"] : []),
    ] as InsightsTab[];
    setTabs(newTabs);
    if (canViewMacro && activeTab !== "Macro") {
      setActiveTab("Macro");
    } else if (!newTabs.includes(activeTab)) {
      setActiveTab(newTabs[0]);
    }
  }, [canViewMacro, canViewThemes]);

  const value = useMemo(
    () => ({
      tabs,
      activeTab,
      setActiveTab,
      canViewMacro,
      canViewThemes,
    }),
    [tabs, activeTab, canViewMacro, canViewThemes],
  );

  return (
    <InsightsContext.Provider value={value}>
      {children}
    </InsightsContext.Provider>
  );
};
