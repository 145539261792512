import React, { useState } from "react";
import { AuthProvider } from "../../contexts/authContext";
import { InsightsProvider } from "../../contexts/insightsContext";

const Wrapper = (props) => {
  return (
    <AuthProvider>
      <InsightsProvider>
        <div>{props.children}</div>
      </InsightsProvider>
    </AuthProvider>
  );
};

export default Wrapper;
