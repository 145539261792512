exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-datasets-dataset-index-tsx": () => import("./../../../src/pages/datasets/dataset/index.tsx" /* webpackChunkName: "component---src-pages-datasets-dataset-index-tsx" */),
  "component---src-pages-datasets-index-tsx": () => import("./../../../src/pages/datasets/index.tsx" /* webpackChunkName: "component---src-pages-datasets-index-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insights-index-tsx": () => import("./../../../src/pages/insights/index.tsx" /* webpackChunkName: "component---src-pages-insights-index-tsx" */),
  "component---src-pages-insights-macro-reports-index-tsx": () => import("./../../../src/pages/insights/macro-reports/index.tsx" /* webpackChunkName: "component---src-pages-insights-macro-reports-index-tsx" */),
  "component---src-pages-insights-reports-index-tsx": () => import("./../../../src/pages/insights/reports/index.tsx" /* webpackChunkName: "component---src-pages-insights-reports-index-tsx" */),
  "component---src-pages-insights-themes-reports-index-tsx": () => import("./../../../src/pages/insights/themes-reports/index.tsx" /* webpackChunkName: "component---src-pages-insights-themes-reports-index-tsx" */),
  "component---src-pages-legal-documents-disclaimers-tsx": () => import("./../../../src/pages/legal-documents/disclaimers.tsx" /* webpackChunkName: "component---src-pages-legal-documents-disclaimers-tsx" */),
  "component---src-pages-legal-documents-privacy-policy-tsx": () => import("./../../../src/pages/legal-documents/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-legal-documents-privacy-policy-tsx" */),
  "component---src-pages-legal-documents-uk-regulatory-disclosures-tsx": () => import("./../../../src/pages/legal-documents/uk-regulatory-disclosures.tsx" /* webpackChunkName: "component---src-pages-legal-documents-uk-regulatory-disclosures-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-login-okta-tsx": () => import("./../../../src/pages/login/okta.tsx" /* webpackChunkName: "component---src-pages-login-okta-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-terms-of-use-index-tsx": () => import("./../../../src/pages/terms-of-use/index.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-index-tsx" */)
}

